import React, { useRef, useState, useEffect } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { db } from "../../firebase/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const ExportExcel = () => {
  const tableRef = useRef(null);
  const [equipements, setEquipements] = useState([]);
  const [listUsers, setListUsers] = useState<any>();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Extraction Equipements",
    sheet: "Equipements",
  });

  const toolsList = async () => {
    const q = query(collection(db, "Tools"), where("designation", "!=", ""));
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setEquipements(values);
    });
  };

  const usersList = async () => {
    if (!listUsers) {
      const q = query(collection(db, "Users"), where("status", "!=", "admin"));
      onSnapshot(q, (querySnapshot) => {
        const values: any = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data() });
        });
        setListUsers(values);
      });
    }
  };

  const technician = (techobjid: string) => {
    if (techobjid === "NA") {
      return "NA";
    }
    const tech: any = listUsers && listUsers.find((user: any) => user.uid === techobjid);
    return `${tech?.firstname} ${tech?.lastname}`;
  };

  useEffect(() => {
    usersList();
    toolsList();
    // eslint-disable-next-line
  }, []);  

  return (
    <div
      id="export-table"
      style={{ backgroundColor: "white", padding: "30px" }}
    >
      <Button
        onClick={onDownload}
        variant="outlined"
        color="success"
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Télécharger le fichier Excel
      </Button>
      <table ref={tableRef} style={{ display: "none" }}>
        <tbody>
          <tr>
            <th>Designation</th>
            <th>Id constructeur</th>
            <th>Numéro interne</th>
            <th>Technicien</th>
            <th>Controlé le</th>
            <th>Prochain controle</th>
            <th>Status</th>
          </tr>
          {equipements.map((equipement: any) => (
            <tr key={equipement.id}>
              <td>{equipement.designation}</td>
              <td>{equipement.constructid}</td>
              <td>{equipement.internalnumber}</td>
              <td>{technician(equipement.techobjid)}</td>
              <td>{equipement.controldate}</td>
              <td>{equipement.nextcontroldate}</td>
              <td>{equipement.status}</td>
            </tr>
          )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExportExcel;
