import React, { Fragment } from "react";
import { Container } from "@mui/material";
import "./menu.css";
import Footer from "../footer/Footer";
// import Loged from "./Loged";
import { IsLoggedIn } from "../../Utils/fonctions";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  IsLoggedIn();
  const navigate = useNavigate();

  // Permet de déconnecter proprement l'utilisateur et redirige vers la connexion
  async function logOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  }
  return (
    <Fragment>
      <div id="menu">
        <Container maxWidth="md">
          <div id="img_elec"></div>
          {/* <Loged /> */}
          <div id="menu_manager" data-testid="data-menu">
            <ul>
              <li>
                <a href="/home">Home</a>
              </li>
              <li>
                <a href="/equipements">Equipements</a>
              </li>
              <li>
                <a href="/techniciens">Techniciens</a>
              </li>
              <li>
                <a href="/etalon">Simualteur étalon</a>
              </li>
              <li>
                <a href="/parameters">Paramètres</a>
              </li>
              <li>
                <a href="#logout" id="logout_link" onClick={() => logOut()}>
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
          <Footer />
        </Container>
      </div>
    </Fragment>
  );
};

export default Menu;
