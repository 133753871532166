import React from "react";
import "./loged.css";
import { dataLogProps } from "../../types/userTypes";

export default function Connected(props: any) {
  if (props.dataLog) {
    const { firstname, lastname, status } = props.dataLog;
    return (
      <div id="loged">
        <ul>
          <p className="auth-name" data-testid="auth-name">
            {`${firstname} ${lastname}`}
          </p>
          <p className="auth-role" data-testid="auth-role">
            {status}
          </p>
        </ul>
      </div>
    );
  } else {
    return (
      <div id="loged">
        <ul>
          <p className="auth-name">Chargement</p>
          <p className="auth-role">en cours ...</p>
        </ul>
      </div>
    );
  }
}
