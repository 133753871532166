import React from "react";
import { Container } from "@mui/material";
import SignIn from "./SignIn";
import HeaderConnexion from "./HeaderConnexion";
import "./connexion.css";

const Connexion = () => {
  return (
    <div id="connexion">
      <Container maxWidth="sm">
        <HeaderConnexion />
        <SignIn />
      </Container>
    </div>
  );
};

export default Connexion;
