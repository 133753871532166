import React, { useState, useEffect } from "react";
import "./mostrecents.css";
import { Container, Box } from "@mui/material";
import { colorPuce } from "../../../Utils/fonctions";
import { db } from "../../../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import dayjs from "dayjs";

const MostRecents = () => {
  const [parameters, setParameters] = useState<any>();
  const [toolsAwControl, setToolsAwControl] = useState<any>();
  const limitedDate = parameters
    ? dayjs().add(parameters?.checkingdays, "days").format("YYYY-MM-DD")
    : null;

  // si la date de prochain controle est inférieur à la date d'aujourd'hui + "ckeckindays" jours
  const awControlTools = async () => {
    if (limitedDate) {
      const q = query(
        collection(db, "Tools"),
        where("nextcontroldate", "<", limitedDate),
        where("nextcontroldate", ">=", dayjs().format("YYYY-MM-DD"))
      );
      const querySnapshot = await getDocs(q);
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setToolsAwControl(values);
    }
  };

  const getParameters = async () => {
    const q = query(collection(db, "Settings"));
    const querySnapshot = await getDocs(q);
    const values: any = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setParameters(values[0]);
  };

  useEffect(() => {
    if (!parameters) {
      getParameters();
    }
  });

  useEffect(() => {
    if (!toolsAwControl) {
      awControlTools();
    }
  });

  const equipements = toolsAwControl?.map((tool: any, key: any) => {
    return (
      <a href={`/equipementedit?id=${tool.id}`} key={key}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            backgroundColor: "white",
            textAlign: "center",
            border: "2px solid #ffcdd2",
          }}
          my={1}
          key={key}
        >
          <Box sx={{ py: 1, bgcolor: "#ffebee", width: "50%" }}>
            {tool.designation}
          </Box>
          <Box sx={{ py: 1, bgcolor: "#ffebee", width: "25%" }}>
            {tool.internalnumber}
          </Box>
          <Box sx={{ py: 1, bgcolor: "#ffebee", width: "25%" }}>
            {tool.nextcontroldate}
          </Box>
          <Box sx={{ py: 1, bgcolor: "#ffebee", width: "10%" }}>
            <div className="circle" style={colorPuce(tool.status)}></div>
          </Box>
        </Box>
      </a>
    );
  });

  return (
    <div id="mostrecents">
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            backgroundColor: "white",
            textAlign: "center",
            color: "gray",
          }}
        >
          <Box sx={{ pt: 1, width: "50%" }}>Désignation</Box>
          <Box sx={{ pt: 1, width: "25%" }}>Numéro</Box>
          <Box sx={{ pt: 1, width: "25%" }}>Contrôle</Box>
          <Box sx={{ pt: 1, width: "10%" }}></Box>
        </Box>
        <Container maxWidth="md">
          {toolsAwControl?.length > 0 ? (
            equipements
          ) : (
            <Box mt={1} sx={{ py: 1, bgcolor: "#c8e6c9", width: "100%" }}>
              <Box px={2}>Tous les équipements sont à jour</Box>
            </Box>
          )}
        </Container>
      </Container>
    </div>
  );
};
export default MostRecents;
