import React, { useState, useEffect } from "react";
import "./stats.css";
import { db } from "../../../firebase/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import dayjs from "dayjs";

const Stats = () => {
  const [parameters, setParameters] = useState<any>();
  const [toolsCount, setToolsCount] = useState<any>();
  const [usersCount, setUsersCount] = useState<any>();
  const [toolsGood, setToolsGood] = useState<any>();
  const [toolsNotGood, setToolsNotGood] = useState<any>();
  const [toolsAwControl, setToolsAwControl] = useState<any>();

  const limitedDate = parameters
    ? dayjs().add(parameters.checkingdays, "days").format("YYYY-MM-DD")
    : null;

  const countTools = async () => {
    const q = query(collection(db, "Tools"), where("designation", "!=", ""));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setToolsCount(values.length);
  };

  const countUsers = async () => {
    const q = query(collection(db, "Users"), where("status", "!=", "admin"));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setUsersCount(values.length);
  };

  const goodTools = async () => {
    const q = query(collection(db, "Tools"), where("status", "==", "Conforme"));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setToolsGood(values.length);
  };

  const notGoodTools = async () => {
    const q = query(
      collection(db, "Tools"),
      where("status", "==", "Non Conforme")
    );
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setToolsNotGood(values.length);
  };

  const getParameters = async () => {
    const q = query(collection(db, "Settings"));
    const querySnapshot = await getDocs(q);
    const values: any = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    setParameters(values[0]);
  };

  // si la date de prochain controle est inférieur à la date d'aujourd'hui + "ckeckindays" jours
  const awControlTools = async () => {
    if (limitedDate) {
      const q = query(
        collection(db, "Tools"),
        where("nextcontroldate", "<", limitedDate),
        where("nextcontroldate", ">=", dayjs().format("YYYY-MM-DD"))
      );
      const querySnapshot = await getDocs(q);
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setToolsAwControl(values.length);
    }
  };

  useEffect(() => {
    getParameters();
    countTools();
    countUsers();
    goodTools();
    notGoodTools();
  }, []);

  useEffect(() => {
    if (parameters) {
      awControlTools();
    }
  });

  return (
    <div id="stats">
      <p>Statistiques</p>
      <table>
        <tr>
          <td>Techniciens</td>
          <td className="infos">{usersCount}</td>
        </tr>
        <tr>
          <td>Matériels</td>
          <td className="infos">{toolsCount}</td>
        </tr>
        <tr>
          <td>Conforme</td>
          <td className="infos">{toolsGood}</td>
        </tr>
        <tr>
          <td>Non conforme</td>
          <td className="infos">{toolsNotGood}</td>
        </tr>
        <tr>
          <td>En attente de contrôle</td>
          <td className="infos">{toolsAwControl}</td>
        </tr>
      </table>
    </div>
  );
};

export default Stats;
