import React, { useState, useEffect } from "react";
import "./equipements.css";
import { Container, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import Footer from "../../footer/Footer";
import ExportExcel from "../../export_excel/ExportExcel";
import addImg from "../../../medias/add.png";
import { colorPuce } from "../../../Utils/fonctions";
import { db } from "../../../firebase/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import dayjs from "dayjs";

const Equipements = () => {
  const page = "Equipements";
  const [equipements, setEquipements] = useState([]);

  const toolsList = async () => {
    const q = query(collection(db, "Tools"), where("designation", "!=", ""));
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setEquipements(values);
    });
  };

  useEffect(() => {
    toolsList();
  }, []);

  const handleChange = (e: { target: { value: string | any[]; }; }) => {
    if (e.target.value.length > 1) {
      const newEquipment = equipements.filter((equipment: any) =>
        equipment.internalnumber.includes(e.target.value)
      );
      setEquipements(newEquipment);
    } else {
      toolsList();
    }
  };

  function Items({ currentItems }: any) {
    return (
      <>
        {currentItems?.map((tool: any, key: any) => (
          <a href={`/equipementedit?id=${tool.id}`} key={key}>
            <Box
              id="BoxEquipements"
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                p: 1,
                backgroundColor: "white",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <Box sx={{ py: 1, bgcolor: "grey.300", width: "50%" }}>
                {tool.designation}
              </Box>
              <Box sx={{ py: 1, bgcolor: "grey.300", width: "25%" }}>
                {tool.internalnumber}
              </Box>
              <Box sx={{ py: 1, bgcolor: "grey.300", width: "25%" }}>
                {tool.nextcontroldate < dayjs().format("YYYY-MM-DD") ? (
                  <span style={{ color: "red" }}>{tool.nextcontroldate}</span>
                ) : (
                  <span>{tool.nextcontroldate}</span>
                )}
              </Box>
              <Box sx={{ py: 1, bgcolor: "grey.300", width: "10%" }}>
                <div className="circle" style={colorPuce(tool.status)}></div>
              </Box>
            </Box>
          </a>
        ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }: any) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState<any>(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(equipements.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(equipements.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event: { selected: number; }) => {
      const newOffset = (event.selected * itemsPerPage) % equipements.length;
      setItemOffset(newOffset);
    };

    return (
      <div id="container">
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    );
  }

  return (
    <div id="equipements">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        <ExportExcel />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <TextField
            id="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            placeholder="Search"
            sx={{ width: "50%", pt: "20px" }}
            variant="standard"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            pt: 1,
            backgroundColor: "white",
            textAlign: "center",
            color: "gray",
          }}
        >
          <Box sx={{ pt: 1, width: "50%" }}>Désignation</Box>
          <Box sx={{ pt: 1, width: "25%" }}>Numéro</Box>
          <Box sx={{ pt: 1, width: "25%" }}>Contrôle</Box>
          <Box sx={{ pt: 1, width: "10%" }}></Box>
        </Box>
        <PaginatedItems itemsPerPage={10} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <a href="/equipementadd">
            <img className="addImg" src={addImg} width="60px" alt="plus" />
          </a>
        </Box>
        <Footer />
      </Container>
    </div>
  );
};

export default Equipements;
