import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWTHc4eZj1VU20aR85Ygl3H_HZGfYjjRA",
  authDomain: "qualitech-3bb59.firebaseapp.com",
  databaseURL:
    "https://qualitech-3bb59-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "qualitech-3bb59",
  storageBucket: "qualitech-3bb59.appspot.com",
  messagingSenderId: "416440129574",
  appId: "1:416440129574:web:2b5d023d08d280673aaee7",
  measurementId: "G-2LZ95BGLXP",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
