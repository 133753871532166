import React, { useState } from "react";
import { Link, TextField, CssBaseline, Container, Button } from "@mui/material";
import "./signin.css";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const auth = getAuth();

  async function loginEmailPassword(email: string, password: string) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate("/home");
      })
      .catch((error) => {
        setEmail("");
        setPassword("");
      });
  }

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    loginEmailPassword(email, password);
  };

  return (
    <div id="signin">
      <Container maxWidth="sm">
        <CssBaseline />
        <div className="paper">
          <p className="title">Connexion</p>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type="password"
              value={password}
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit"
              sx={{ mt: 2 }}
            >
              <Link className="connect">Se connecter</Link>
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}
