import React, { useState, useEffect } from "react";
import "./etalon.css";
import { Container, Box, TextField } from "@mui/material";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import Footer from "../../footer/Footer";

const Etalon = () => {
  const page = "Simulateur étalon";
  const [valMesuree, setValMesuree] = useState<number | null>(null);
  const [valEtalon, setValEtalon] = useState<number | null>(null);
  const [resultat, setResultat] = useState<number | null>(null);

  useEffect(() => {
    if (valMesuree && valEtalon) {
      calculEtalonnage(valMesuree, valEtalon);
    }
  }, [valMesuree, valEtalon]);

  function calculEtalonnage(valeurMesuree: number, valeurEtalon: number) {
    const result = ((valeurMesuree - valeurEtalon) / valeurEtalon) * 100;
    // Résultat arrondi à 3 chiffres après la virgule
    setResultat(result);
  }

  return (
    <div id="etalon">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        <Box sx={{ backgroundColor: "white", textAlign: "center" }}>
          <Box sx={{ padding: "20px", margin: "0 auto", width: "50%" }}>
            <p className="valueLabel">Valeur Mesurée</p>
            <TextField
              id="filled-basic"
              variant="outlined"
              type="number"
              onChange={(e: any) => setValMesuree(e.target.value)}
            />
          </Box>
          <Box sx={{ padding: "20px", margin: "0 auto", width: "50%" }}>
            <p className="valueLabel">Valeur Etalon</p>
            <TextField
              id="filled-basic"
              variant="outlined"
              type="number"
              onChange={(e: any) => setValEtalon(e.target.value)}
            />
          </Box>
          <Box sx={{ padding: "20px" }}>
            <p id="resultLabel">Résultat</p>
            <p id="result">
              {!valMesuree || !valEtalon ? "" : resultat && resultat.toFixed(3)}
            </p>
          </Box>
        </Box>
        <Footer />
      </Container>
    </div>
  );
};

export default Etalon;
