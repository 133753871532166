import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const IsLoggedIn = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      return uid;
    } else {
      navigate("/");
    }
  });
};

export const colorPuce = (status: string) => {
  if (status === "Conforme") {
    return { backgroundColor: "#4caf50" };
  } else if (status === "Non Conforme") {
    return { backgroundColor: "#f44336" };
  } else if (status === "Réformé") {
    return { backgroundColor: "black" };
  } else {
    return { backgroundColor: "white" };
  }
};
