import React from "react";
import { Container, Box } from "@mui/material";

function HeaderConnexion() {
  return (
    <Container maxWidth="sm">
      <div id="headerConnexion">
        <Box data-testid="header-title">
          <h1>
            Quali<span className="tech_title">Tech</span>
          </h1>
        </Box>
        <Box data-testid="header-description">
          Outil de gestion des équipements de contrôle
        </Box>
      </div>
    </Container>
  );
}

export default HeaderConnexion;
