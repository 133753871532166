import React, { useState, useEffect } from "react";
import "./techniciens.css";
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import Footer from "../../footer/Footer";
import { db } from "../../../firebase/firebase";
import { colorPuce } from "../../../Utils/fonctions";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";

const Techniciens = () => {
  const page = "Techniciens";
  const [listUsers, setListUsers] = useState<any>(null);
  const techId = window.location.search.split("=")[1];
  const [personName, setPersonName] = useState<any>(null);
  const [equipements, setEquipements] = useState([]);
  const techInfos = listUsers?.find((user: any) => user.uid === techId);
  const techName = `${techInfos?.firstname} ${techInfos?.lastname}`;

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    toolsList();
  };

  const toolsList = async () => {
    const q = query(
      collection(db, "Tools"),
      techId && !personName
        ? where("techobjid", "==", `${techId}`)
        : where("techobjid", "==", `${personName?.uid}`)
    );
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setEquipements(values);
    });
  };

  useEffect(() => {
    if (!listUsers) {
      usersList();
    }
  }, [listUsers]);

  useEffect(() => {
    toolsList();
    // eslint-disable-next-line
  }, [personName]);

  const usersList = async () => {
    const q = query(collection(db, "Users"), where("status", "!=", "admin"));
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), docId: doc.id });
      });
      setListUsers(values);
    });
  };

  const selectTech = (listUsers: any) => {
    if (listUsers) {
      return (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">
            {techInfos ? techName : "Choix du technicien"}
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={personName || ""}
            onChange={handleChange}
            input={<OutlinedInput label="Choix du technicien" />}
          >
            {listUsers.map((name: any) => (
              <MenuItem key={name.uid} value={name}>
                {name.firstname} {name.lastname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <div id="techniciens">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        <Box sx={{ backgroundColor: "white", textAlign: "center" }}>
          <br />
          {selectTech(listUsers)}
          {personName && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                pt: 1,
                backgroundColor: "white",
                textAlign: "center",
                color: "gray",
              }}
            >
              <Box sx={{ pt: 1, width: "50%" }}>Désignation</Box>
              <Box sx={{ pt: 1, width: "25%" }}>Numéro</Box>
              <Box sx={{ pt: 1, width: "25%" }}>Contrôle</Box>
              <Box sx={{ pt: 1, width: "10%" }}></Box>
            </Box>
          )}
          {(!equipements || equipements.length < 1) && personName ? (
            <p className="no-equipements">Aucun équipement attribué</p>
          ) : (
            equipements.map((tool: any, key: any) => (
              <a href={`/equipementedit?id=${tool.id}`} key={key}>
                <Box
                  id="BoxEquipements"
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    p: 1,
                    backgroundColor: "white",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  <Box sx={{ py: 1, bgcolor: "grey.300", width: "50%" }}>
                    {tool.designation}
                  </Box>
                  <Box sx={{ py: 1, bgcolor: "grey.300", width: "25%" }}>
                    {tool.internalnumber}
                  </Box>
                  <Box sx={{ py: 1, bgcolor: "grey.300", width: "25%" }}>
                    {tool.nextcontroldate < dayjs().format("YYYY-MM-DD") ? (
                      <span style={{ color: "red" }}>
                        {tool.nextcontroldate}
                      </span>
                    ) : (
                      <span>{tool.nextcontroldate}</span>
                    )}
                  </Box>
                  <Box sx={{ py: 1, bgcolor: "grey.300", width: "10%" }}>
                    <div
                      className="circle"
                      style={colorPuce(tool.status)}
                    ></div>
                  </Box>
                </Box>
              </a>
            ))
          )}
        </Box>
        <Footer />
      </Container>
    </div>
  );
};

export default Techniciens;
