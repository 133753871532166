import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import "./navbar.css";
import menu from "../../medias/menu.png";
import { IsLoggedIn } from "../../Utils/fonctions";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

interface NavbarProps {
  page?: string;
}

const Navbar: React.FC<NavbarProps> = ({page}) => {
  IsLoggedIn();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState<number>();

  // Permet de déconnecter proprement l'utilisateur et redirige vers la connexion
  async function logOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  }

  //listen for window resize event
  window.addEventListener("resize", function () {
    const newWidth: number = window.innerWidth;
    setWindowWidth(newWidth);
  });

  const showNavBar = () => {
    if (window.innerWidth <= 650) {
      return (
        <div id="navbar">
          <Link to="/menu">
            <img className="menu_burger" src={menu} alt="menu burger img" />
          </Link>
          <li id="title">{page}</li>
        </div>
      );
    } else {
      return (
        <div id="navbarFull">
          <Stack direction="row" m={2} spacing={3} justifyContent="flex-end">
            <a href="/home" className="linkNav">
              Home
            </a>
            <a href="/equipements" className="linkNav">
              Equipements
            </a>
            <a href="/techniciens" className="linkNav">
              Techniciens
            </a>
            <a href="/etalon" className="linkNav">
              Simulateur étalon
            </a>
            <a href="/parameters" className="linkNav">
              Paramètres
            </a>
            <a href="#logout" id="logout_linkFull" onClick={() => logOut()}>
              Déconnexion
            </a>
          </Stack>
        </div>
      );
    }
  };

  return showNavBar();
};

export default Navbar;
