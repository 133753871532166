import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { IsLoggedIn } from "../../Utils/fonctions";
import Connected from "./Connected";

const Loged = () => {
  IsLoggedIn();
  const [currentEmail, setCurrentEmail] = useState<string>();
  const [dataLog, setDataLog] = useState<any>();

  useEffect(() => {
    if (!currentEmail) {
      logIn();
    }
  });

  useEffect(() => {
    if (!dataLog) {
      getUser();
    }
  });

  const logIn = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        setCurrentEmail(user.email);
        return user.email;
      }
    });
  };

  async function getUser() {
    if (currentEmail) {
      const q = query(
        collection(db, "Users"),
        where("email", "==", currentEmail)
      );
      onSnapshot(q, (querySnapshot) => {
        const values: any[] = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data() });
        });
        setDataLog(values[0]);
      });
    }
  }
  return <Connected dataLog={dataLog} />;
};

export default Loged;
