import React, { useState, useEffect } from "react";
import "../equipements/equipementadd.css";
import { nanoid } from "nanoid";
import {
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  List,
  ListItem,
  IconButton,
  Grid,
  Typography,
  Slider,
} from "@mui/material";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import Footer from "../../footer/Footer";
import { redirect } from "react-router-dom";
import { isEqual } from "lodash";
import { db } from "../../../firebase/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";

const Parameters = () => {
  const page = "Paramètres";
  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
  };
  const [toolTech, setToolTech] = useState<any>();
  const [listUsers, setListUsers] = useState<any>();
  const isActive = isEqual(toolTech, initialState);
  const [parameters, setParameters] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [techInfoDelete, setTechInfoDelete] = useState<any>({});

  const handleDeleteClickOpen = (docId: any, techId: any, firstName: string, lastName: string) => {
    setTechInfoDelete({
      docId: docId,
      techId: techId,
      firstName: firstName,
      lastName: lastName,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const usersList = async () => {
    const q = query(collection(db, "Users"), where("status", "!=", "admin"));
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), docId: doc.id });
      });
      setListUsers(values);
    });
  };

  const handleChange = (e: any) => {
    setToolTech({ ...toolTech, [e.target.id]: e.target.value.trim() });
  };

  useEffect(() => {
    usersList();
    getParameters();
  }, []);

  function addTech() {
    if (toolTech) {
      addDoc(collection(db, "Users"), {
        ...toolTech,
        status: "tech",
        uid: nanoid(),
      });
      // resetTechForm();
      redirect("/parameters");
    } else {
      console.warn("erreur");
    }
  }

  async function deleteTech(docId: any, uid: any) {
    // récupère les documents qui on le techobjid égale à l'uid
    const q = query(collection(db, "Tools"), where("techobjid", "==", uid));
    onSnapshot(q, (querySnapshot) => {
      // On supprime les documents
      querySnapshot.forEach((doc) => {
        updateDocument(doc.id);
      });
    });
    if (docId) {
      await deleteDoc(doc(db, "Users", docId));
    }
    redirect("/parameters");
  }

  const updateDocument = (docIdTool: any) => {
    updateDoc(doc(db, "Tools", docIdTool), {
      techobjid: "",
    });
  };

  const marks = [
    {
      value: 15,
      label: "15 j",
    },
    {
      value: 30,
      label: "30 j",
    },
    {
      value: 45,
      label: "45 j",
    },
    {
      value: 60,
      label: "60 j",
    },
    {
      value: 75,
      label: "75 j",
    },
    {
      value: 90,
      label: "90 j",
    },
  ];

  const getParameters = async () => {
    const q = query(collection(db, "Settings"));
    onSnapshot(q, (querySnapshot) => {
      const values: any = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data() });
      });
      setParameters(values[0]);
    });
  };

  const handleParametersChange = (e: any) => {
    setDoc(doc(db, "Settings", "ZtUyEUht0Yu3PnDBvgus"), {
      checkingdays: e.target.value,
    });
  };

  // const resetTechForm = () => {
  //   document.getElementById("tech-form")?.reset();
  // };

  return (
    <div id="equipementadd">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de suppression de l'équipement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez vous vraiment supprimer {techInfoDelete.lastName}{" "}
              {techInfoDelete.firstName} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={() => {
                deleteTech(techInfoDelete.docId, techInfoDelete.techId);
                handleClose();
              }}
              autoFocus
            >
              Oui, Supprimer
            </Button>
          </DialogActions>
        </Dialog>
        <Box mx="auto" style={{ backgroundColor: "white", padding: 10 }}>
          <Box className="content" width="90%" mx="auto">
            <Typography sx={{ mt: 2, mb: 2 }} variant="h5" component="div">
              Filtrage des contrôles récents
            </Typography>
            {parameters && (
              <Slider
                aria-label="checkingdays"
                defaultValue={parameters.checkingdays}
                valueLabelDisplay="auto"
                step={15}
                marks={marks}
                min={15}
                max={90}
                size="medium"
                onChange={handleParametersChange}
              />
            )}

            <Typography
              sx={{ mt: 2, mb: 5, color: "#616161" }}
              variant="h6"
              component="div"
            >
              Réglez le filtrage des jours pour les équipements proche du
              contôle.
            </Typography>
            <Grid item xs={12} md={6}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="h5" component="div">
                Listing des techniciens
              </Typography>
              <Typography
                sx={{ mt: 2, mb: 2, color: "red" }}
                variant="h6"
                component="div"
              >
                Attention opération immédiate et irréversible sans confirmation
              </Typography>
              <List>
                {listUsers?.map((tech: any, key: any) => {
                  return (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon
                            onClick={() =>
                              handleDeleteClickOpen(
                                tech.docId,
                                tech.uid,
                                tech.firstname,
                                tech.lastname
                              )
                            }
                          />
                        </IconButton>
                      }
                      key={key}
                    >
                      {tech.lastname} {tech.firstname}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Typography
              sx={{ mt: 2, mb: 5, color: "#616161" }}
              variant="h6"
              component="div"
            >
              Supprimer un technicien remettra à zéro les assignations de ses
              équipements.
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h5" component="div">
              Ajouter un technicien
            </Typography>
            <form id="tech-form" autoComplete="off">
              <TextField
                id="firstname"
                label="Prénom"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                required
              />
              <TextField
                id="lastname"
                label="Nom"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                required
              />
              <TextField
                id="email"
                label="Adresse email"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={handleChange}
              />
              <br />
              <Box mt={2} sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isActive}
                  onClick={() => addTech()}
                >
                  Ajouter
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Footer />
      </Container>
    </div>
  );
};

export default Parameters;
