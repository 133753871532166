import React from "react";
import "./footer.css";
import { Container } from "@mui/material";

const Footer = () => {
  return (
    <Container maxWidth="sm">
      <div id="footer" data-testid="footer">
        <h4>
          Quali
          <span className="footer_title">Tech</span>
        </h4>
      </div>
    </Container>
  );
};

export default Footer;
