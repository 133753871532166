import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import "./home.css";
import Navbar from "../../navbar/Navbar";
import Loged from "../../loged/Loged";
import MostRecents from "./MostRecents";
import Stats from "./Stats";
import Footer from "../../footer/Footer";
import { db } from "../../../firebase/firebase";
import { collection, query, onSnapshot } from "firebase/firestore";

const Home = () => {
  const page = "Home";
  const [parameters, setParameters] = useState<any>();
  const filter = (
    <div className="filter">
      <p>Contrôle sous {parameters?.checkingdays} jours</p>
    </div>
  );

  const getParameters = async () => {
    if (!parameters) {
      const q = query(collection(db, "Settings"));
      onSnapshot(q, (querySnapshot) => {
        const values: any = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data() });
        });
        setParameters(values[0]);
      });
    }
  };

  useEffect(() => {
    if (!parameters) {
      getParameters();
    }
  });

  return (
    <div id="home">
      <Container maxWidth="md">
        <Navbar page={page} />
        <Loged />
        {filter}
        <MostRecents />
        <Stats />
        <Footer />
      </Container>
    </div>
  );
};

export default Home;
