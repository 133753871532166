import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

// Pages Mixtes
import Connexion from "./components/pages/connexion/Connexion";
import Navbar from "./components/navbar/Navbar";
import Loged from "./components/loged/Loged";
import Home from "./components/pages/home/Home";
import MostRecents from "./components/pages/home/MostRecents";
import Stats from "./components/pages/home/Stats";
import Footer from "./components/footer/Footer";
import Equipements from "./components/pages/equipements/Equipements";
import EquipementAdd from "./components/pages/equipements/EquipementAdd";
import EquipementEdit from "./components/pages/equipements/EquipementEdit";
import Techniciens from "./components/pages/techniciens/Techniciens";
import Parameters from "./components/pages/parameters/Parameters";
import Menu from "./components/menu/Menu";
import Etalon from "./components/pages/etalon/Etalon";

const Root = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Connexion />} />
      <Route path="/navbar" element={<Navbar />} />
      <Route path="/loged" element={<Loged />} />
      <Route path="/home" element={<Home />} />
      <Route path="/mostrecents" element={<MostRecents />} />
      <Route path="/stats" element={<Stats />} />
      <Route path="/footer" element={<Footer />} />
      <Route path="/equipements" element={<Equipements />} />
      <Route path="/equipementadd" element={<EquipementAdd />} />
      <Route path="/equipementedit" element={<EquipementEdit />} />
      <Route path="/techniciens" element={<Techniciens />} />
      <Route path="/parameters" element={<Parameters />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/etalon" element={<Etalon />} />
    </Routes>
  </Router>
);

const container = document.getElementById("root");
const root = container && createRoot(container);
root && root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
